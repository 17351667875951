import React, { Component } from "react";
import SetUser from '../components/SetUser';

class Home extends Component {
  render() {
    return (
      <div className="container">
        <SetUser />
      </div>
    );
  }
}
 
export default Home;